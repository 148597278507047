@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/UI/Pages/Landing/Sections/base.scss";
@import "@/evercall/ui/styles/variables.scss";

.landingPageContainer {
  overflow: hidden;
  padding-top: 66px;
  display: block;
  width: 100%;
  background-color: $defaultBackgroundColor;

  @include mqMaxWidth($screenS) {
    margin-bottom: 0;
    padding-top: 0;
  }
}

.landingHomeContainer {
  background-color: #fff;
  padding: 0;
}

.staticPageHeaderTitle {
  @extend .sectionTitle;
  margin: 0;
}

.staticPageHeaderSubTitle {
  @extend .sectionDescription;
  margin: 30px 0 0;

  @include mqMaxWidth($screenS) {
    font-size: 14px;
    line-height: 18px;
    margin: 15px 0 0;
  }
}

.staticPageContainer {
  position: relative;
  z-index: 2;
  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
}

.staticPageHeaderWrapper {
  position: relative;
  z-index: 2;
  padding: 44px 20px 100px;

  @include mqMaxWidth($screenS) {
    padding: 50px 10px;
  }

  @include mqMaxWidth($screenXs) {
    padding: 30px 10px;
  }
}

.dotsPattern {
  position: absolute;
  width: 864px;
  display: inline-block;
  height: 32px;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.footerPattern {
  @include mqMaxWidth($screenM) {
    display: block;
  }

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.scheduleGreenDiagonal {
  @include mqMaxWidth($screenM) {
    display: inline-block;
  }

  @include mqMaxWidth($screenS) {
    height: 1750px;
    top: 610px;
  }
}

.pricingGreenDiagonal {
  @include mqMaxWidth($screenM) {
    display: inline-block;
  }

  @include mqMaxWidth(996px) {
    height: 1430px;
  }

  @include mqMaxWidth($screenS) {
    height: 1130px;
  }

  @include mqMaxWidth(730px) {
    height: 1430px;
  }

  @include mqMaxWidth($breakpointWidth) {
    height: 1630px;
  }

  @include mqMaxWidth($screenXs) {
    height: 1830px;
  }

  @include mqMaxWidth(350px) {
    height: 1930px;
  }
}

.footerContent {
  @include mqMaxWidth($screenS) {
    height: auto;
  }

  .mailLink {
    width: auto;
    max-width: 170px;
  }

  .footerCol {
    width: 25%;
  }
}

.mobileMenu {
  background: linear-gradient(249.96deg, #219d8e 1.76%, #4e7b9c 100%);
}
