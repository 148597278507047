$tenantEvercallColor: #5acdbf;
$baseBackgroundColor: #f3f3f3;
$baseTextLightColor: #c0c0c0;
$secondaryColor: #616161;
$secondaryColorHover: #2d2d2d;

$baseFontFamily: "Open Sans", Arial, Helvetica, sans-serif;

$pageContainerMarginTopTablet: 50px;
$pageContainerMarginTopMobile: 30px;

$containerWidth: 1196px;
$breakpointWidth: 640px;

$evercallGradient: linear-gradient(250deg, #5acdbf 40.28%, #5e8dae 100%);

:export {
  tenantEvercallColor: $tenantEvercallColor;
}
